import React, { useState, useEffect } from 'react';
import './HRVDataSetForm.css';
import './HrvSingleRecording.css';
import "./index.css"
import { logEvent } from 'firebase/analytics';
import { analytics } from './index.js';
import FileSaver from 'file-saver';
import db from './config/firebase_config.js';
import { collection, addDoc, doc, setDoc } from "firebase/firestore";
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { set } from 'firebase/database';

const ExpandablePre = ({ result, defaultLines = 10 }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const content = JSON.stringify(result, null, 2); // Properly formatted JSON
  const displayedLines = isExpanded ? content.split('\n').length : defaultLines;

  return (
    <div>
      <pre className="results">
        {content.split('\n').slice(0, displayedLines).join('\n')}
        {displayedLines < content.split('\n').length && (
          <button onClick={handleToggleExpansion}>
            {isExpanded ? 'Collapse' : 'Expand'}
          </button>
        )}
      </pre>
    </div>
  );
};


const HRVSingleRecordingForm = () => {
  const [file, setFile] = useState(null);
  const [result, setResult] = useState([]);
  const [dataType, setDataType] = useState('PPG');
  const [samplingRate, setSamplingRate] = useState(64); // Initialize sampling rate to 0
  const [segment, setSegment] = useState(false);
  const [segmentLength, setSegmentLength] = useState(60);
  const [segmentOverlap, setSegmentOverlap] = useState(0.0);
  const [submitting, setSubmitting] = useState(false);
  const [isMultipleFiles, setIsMultipleFiles] = useState(false);
  const [progress, setProgress] = useState(0);
  const [totalSegments, setTotalSegments] = useState(0);
  const [user, setUser] = useState(null);

  const URL = 'https://hrvwebapi-flask-76wlsdg7yq-lm.a.run.app/calculate'
  //const URL = 'http://127.0.0.1:8444/calculate'
  const URL_SEGMENTS = 'https://hrvwebapi-flask-76wlsdg7yq-lm.a.run.app/calculate_with_epoch'
  //const URL_SEGMENTS = 'http://127.0.0.1:8444/calculate_with_epoch'
  const URL_MULTIPLE = 'https://hrvwebapi-flask-76wlsdg7yq-lm.a.run.app/calculate_multiple_files'
  //const URL_MULTIPLE = 'http://127.0.0.1:8444/calculate_multiple_files'

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = auth.onAuthStateChanged(user => {
      setUser(user);
    });
    return unsubscribe;
  }, []);

  const uploadFileToStorage = async (file, userId) => {
    const storage = getStorage();
    const fileRef = ref(storage, `user-files/${userId}/${file.name}`);
    await uploadBytes(fileRef, file);
    return fileRef.fullPath;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === 'data_type') {
      setDataType(value);
    } else if (name === 'sampling_rate') {
      setSamplingRate(parseInt(value)); // Ensure numerical value for sampling rate
    } else if (name === 'segment') {
      setSegment(checked);
    } else if (name === 'segment_length') {
      setSegmentLength(parseInt(value, 10));
    } else if (name === 'segment_overlap') {
      setSegmentOverlap(parseFloat(value)); // Use parseFloat for floating-point numbers
    } else
      console.error('Invalid input name');
  };

  function handleFileUpload(event) {
    const uploadedFiles = event.target.files;
    if (uploadedFiles.length > 1) {
      setIsMultipleFiles(true);
      logEvent(analytics, 'Multiple files uploaded');
      setFile(uploadedFiles);
    }
    else {
      const uploadedFile = uploadedFiles[0];
      setFile(uploadedFile);
    }
    logEvent(analytics, 'File uploaded');
  }

  const handleClickDownload = () => {
    // Assuming `result` is an object containing data to download
    console.log('Result:', result);
    const blob = new Blob([JSON.stringify(result)], { type: 'application/json' });
    FileSaver.saveAs(blob, 'result.json');
  };

  const cleanResult = () => {
    setResult(null);
    setFile(null);
  }


  function parseDataToJson(inputString) {
    // Check if the input starts with 'data: '
    if (inputString.startsWith("data: ")) {
      // Remove 'data: ' from the start of the string
      inputString = inputString.substring(6);
    }
    
    // Parse the JSON string and return the resulting object
    try {
      inputString = inputString.replace(/NaN/g, '0');
      inputString = inputString.replace(/Infinity/g, '0');
      console.log('Input:', inputString);
      return JSON.parse(inputString);
    } catch (error) {
      console.error("Failed to parse JSON:", error);
      return {progress : 0} // or handle the error as needed
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setTotalSegments(100);

    if (file && dataType && samplingRate > 0) {
      setSubmitting(true);
      const formData = new FormData();

      const url = isMultipleFiles ? URL_MULTIPLE : (segment ? URL_SEGMENTS : URL);

      let uploadedFilePaths = [];

      if (isMultipleFiles) {
        for (const single of file) {
          formData.append('files[]', single, single.name);
          if (user) {
            const filePath = await uploadFileToStorage(single, user.uid);
            uploadedFilePaths.push(filePath);
          }
        }
      } else {
        formData.append('file', file, file.name);
        if (user) {
          const filePath = await uploadFileToStorage(file, user.uid);
          uploadedFilePaths.push(filePath);
        }
      }
      formData.append('data_type', dataType);
      formData.append('sampling_rate', samplingRate);
      if (segment) {
        formData.append('segment_length', segmentLength);
        formData.append('segment_overlap', segmentOverlap);
      }
      console.log(formData);
      for (let [key, value] of formData.entries()) {
        console.log(`${key}: ${value}`);
      }
      try {
        console.log(formData);
        const response = await fetch(url, {
          method: 'POST',
          body: formData,
        });

        const reader = response.body.pipeThrough(new TextDecoderStream()).getReader()
        let result = '';
        while (true) {
          const { done, value } = await reader.read();
          if (done) {
              console.log("Stream complete" + value);
              break;
          }
          result = parseDataToJson(value);
          setProgress(result.progress);
        }
        const completeData = result;
        setResult(completeData);
        
        console.log('Complete Data:', completeData);
        console.log('Complete Data typ:', completeData.type);
        const now = new Date();
        const timestamp = `${now.getFullYear()}-${(now.getMonth() + 1).toString().padStart(2, '0')}-${now.getDate().toString().padStart(2, '0')}_${now.getHours().toString().padStart(2, '0')}-${now.getMinutes().toString().padStart(2, '0')}-${now.getSeconds().toString().padStart(2, '0')}`;
        
        const userId = user ? user.uid : '';
        console.log('User ID:', userId);
        const documentId = `hrv_record_${userId}_${timestamp}`;
        console.log('Document ID:', documentId);
        await setDoc(doc(db, "hrv_records", documentId), {
          userId: userId,
          hrv_output: completeData,
          createdAt: now.toISOString(),
          dataType: dataType,
          samplingRate: samplingRate,
          segment: segment,
          segmentLength: segmentLength,
          segmentOverlap: segmentOverlap,
          filePaths: uploadedFilePaths,
        });
        console.log("Document written with ID: ", documentId);
      } catch (error) {
        console.error("Error during fetch operation: ", error);
      } finally {
        setSubmitting(false);
      }
    } else {
      console.error('Please select a data type, upload a file, and enter a valid sampling rate.');
    }
  };
  
  return (
    <form onSubmit={handleSubmit} className="hrv-form">
      <fieldset>
        <legend>Upload Files</legend>
        <div className="form-group">
          <label htmlFor="file-upload">Select files:</label>
          <input type="file" id="file-upload" multiple onChange={handleFileUpload} />
          {file && (
            <div className="file-info">
              <p>Selected file(s):</p>
              <ul>
                {Array.isArray(file) ? (
                  file.map((f, index) => <li key={index}>{f.name}</li>)
                ) : (
                  <li>{file.name}</li>
                )}
              </ul>
              <button type="button" onClick={() => setFile(null)}>Remove</button>
            </div>
          )}
        </div>
      </fieldset>

      <fieldset>
        <legend>Data Type</legend>
        <div className="form-group">
          <div>
            <input
              type="radio"
              id="ppg"
              name="data_type"
              value="PPG"
              checked={dataType === 'PPG'}
              onChange={handleChange}
            />
            <label htmlFor="ppg">PPG</label>
          </div>
          <div>
            <input
              type="radio"
              id="ecg"
              name="data_type"
              value="ECG"
              checked={dataType === 'ECG'}
              onChange={handleChange}
            />
            <label htmlFor="ecg">ECG</label>
          </div>
          <div>
            <input
              type="radio"
              id="rrs"
              name="data_type"
              value="RRS"
              checked={dataType === 'RRS'}
              onChange={handleChange}
            />
            <label htmlFor="rrs">RRS</label>
          </div>
        </div>
      </fieldset>

      <fieldset>
        <legend>Sampling Rate</legend>
        <div className="form-group">
          <label htmlFor="sampling-rate">Sampling Rate (Hz):</label>
          <input
            type="number"
            id="sampling-rate"
            name="sampling_rate"
            value={samplingRate}
            onChange={handleChange}
            min="2"
            required
          />
          {samplingRate < 2 && <p className="error">Sampling rate must be at least 2 Hz.</p>}
        </div>
      </fieldset>

      <fieldset>
        <legend>Segmentation</legend>
        <div className="form-group">
          <label htmlFor="segment-toggle">Enable Segmentation:</label>
          <input
            type="checkbox"
            id="segment-toggle"
            name="segment"
            checked={segment}
            onChange={handleChange}
          />
        </div>
        {segment && (
          <>
            <div className="form-group">
              <label htmlFor="segment-length">Segment Length (seconds):</label>
              <input
                type="number"
                id="segment-length"
                name="segment_length"
                value={segmentLength}
                onChange={handleChange}
                min="1"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="segment-overlap">Segment Overlap (0-1):</label>
              <input
                type="number"
                id="segment-overlap"
                name="segment_overlap"
                value={segmentOverlap}
                onChange={handleChange}
                min="0"
                max="1"
                step="0.01"
                required
              />
            </div>
          </>
        )}
      </fieldset>

      <div className="form-actions">
        <button type="submit" disabled={submitting}>
          {submitting ? 'Calculating...' : 'Calculate'}
        </button>
        <button type="reset" onClick={cleanResult} disabled={submitting}>
          Reset
        </button>
      </div>

      {submitting ? (
        <div className="progress">
          <progress value={progress} max={totalSegments} />
          <p>
            {progress} / {totalSegments}
          </p>
        </div>
      ) : result ? (
        <div className="result">
          <h3>Result</h3>
          <ExpandablePre result={result} />
          <button type="button" onClick={handleClickDownload}>
            Download JSON
          </button>
        </div>
      ) : null}
    </form>
  );
};

export default HRVSingleRecordingForm;
